<template>
	<div class="mini">
		<div class="tab">
			<div v-if="examine" :class="{ btnitem: true, currentbtn: currenttab === 'addbingo' }" @click="toURL('addbingo')">并购项目</div>
			<div  v-if="examine" :class="{ btnitem: true, currentbtn: currenttab === 'addxuqiu' }" @click="toURL('addxuqiu')">并购需求</div>
			<div :class="{ btnitem: true, currentbtn: currenttab === 'addnong' }" @click="toURL('addnong')">农地流转</div>
		</div>
		<div class="fengeline"></div>


		<div class="listcontent">
			<div v-if="currenttab === 'addbingo'" class="bingolist">
				<div :class="{ 'bingoitem': true, 'pointer': item.examine }" v-for="item in list" @click="todetail(item)">
					<img class="cover" :src="item.cover" alt="">
					<div class="rightlist">
						<div style="display:flex;">
							<div v-if="item.examine === 0" class="examine shenhezhong">审核中</div>


							<div class="title">{{ item.title }}</div>
						</div>
						<div class="line">
							<div class="lineLift">项目编号:</div>
							<div class="lineRight">{{ item.number }}</div>
						</div>
						<div class="line">
							<div class="lineLift">用地面积:</div>
							<div class="lineRight">{{ item.extent_value }}</div>
						</div>
						<div class="line">
							<div class="lineLift">项目性质:</div>
							<div class="lineRight">{{ item.attribute_data }}</div>
						</div>
						<div class="line">
							<div class="lineLift">使用年限:</div>
							<div class="lineRight">{{ item.life_data }}</div>
						</div>
						<div class="line">
							<div class="lineLift">容&nbsp;积&nbsp;率&nbsp;:</div>
							<div class="lineRight">{{ item.volumn }}</div>
						</div>
						<div class="line">
							<div class="lineLift">交易方式:</div>
							<div class="lineRight">{{ item.trade_data }}</div>
						</div>
						<div class="line">
							<div class="lineLift">交易报价:</div>
							<div class="lineRight">{{ item.offer }}</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="currenttab === 'addxuqiu'" class="xuqiulist">
				<div :class="{ 'xuqiuitem': true }" v-for="item in list" :key="item.id" >
					<div class="itemtop">
						<div class="title">{{ item.title }}</div>
						<div class="date"><span>发布时间：</span>{{ item.created_at }}</div>
					</div>
					<div class="itembottom">

						<div class="attribute_data blue">{{ item.attribute_data }}</div>
						<div class="extent_data blue">{{ item.extent_data }}</div>
						<div class="life_data blue">{{ item.life_data }}年</div>
						<div class="location"> <img style="width:20px;height:20px;" src="/pic/csqw1.png"><span>{{ item.ss }}</span>
						</div>
					</div>
				</div>

			</div>

			<div v-if="currenttab === 'addnong'" :class="{ 'nonglist': true }">
				<div :class="{ 'nongitem': true, 'pointer': item.examine }" v-for="item in list" :key="item.id"
					@click="todetail(item)">
					<div class="itemtop">
						<div style="display:flex;">
							<div v-if="item.examine === 0" class="examine shenhezhong">审核中</div>

							<div class="title">{{ item.title }}</div>
						</div>

						<!-- <div class="title">{{ item.title }}</div> -->
						<div class="date"><span>发布时间：</span>{{ item.created_at }}</div>
					</div>
				</div>
			</div>
		</div>

		<el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="size" background
			layout="prev, pager, next" :total="total">
		</el-pagination>

	</div>
</template>

<script>


import { detail, save } from '@/api/website/regjjr.js'
import { listBingo } from '@/api/website/bingo'

export default {
	name: 'PcWebsiteAddbingo',

	data() {
		return {
			currenttab: '',
			list: [],
			total: 0,
			page: 1,
			size: 4,
			type:1,
			examine:false
		}
	},

	watch: {
		$route(nv) {
			console.log('nv.query', nv.query)
			if (JSON.stringify(nv.query) !== '{}') {
				const tab = nv.query.tab
				this.currenttab = tab


				switch (this.currenttab) {
					case 'addbingo':
						this.type = 1
						break;
					case 'addxuqiu':
					this.type = 2
						break;
					case 'addnong':
					this.type = 3
						break;
					default:
							this.type = 1;
							break;
				}


				if (nv.query.page !== undefined) {
					this.page = nv.query.page
				} else {
					this.page = 1
				}

				this.getList()
			} else {
				this.currenttab = 'addbingo'
				this.page = 1
			}

		},
		deep: true,
		immediate: true
	},
	mounted() {
		


		detail().then(aa => {
			if (aa.data.examine === 1) {
				// this.$router.push('/cn/list/jjrzc')
				this.examine = true
			}else{
				this.examine = false
			}



			let query = this.$route.query

			if (JSON.stringify(query) !== '{}') {

				let str = '?'
				if (query.tab !== undefined) {
					str += 'tab=' + query.tab + '&'
					this.currenttab = query.tab
				}
				str += 'page=1'

				const url = this.$route.path + str
				this.$router.push(url)


			} else {
				if(this.examine){
					this.currenttab = 'addbingo'
				}else{
					this.currenttab = 'addnong'
				}
			
				this.page = 1
			}
			this.getList()
			
		})

	
	},

	methods: {

		todetail(item) {
			this.$router.push('/cn/detail/jujian/' + item.id)
		},

		handleCurrentChange(page) {


			let query = this.$route.query
			let str = '?'
			if (query.tab !== undefined) {
				str += 'tab=' + query.tab + '&'
				this.currenttab = query.tab
			}else{
				str += 'tab=addbingo&'
			}

			str += 'page=' + page

			const url = this.$route.path + str
			this.$router.push(url)
			// }


		},
		getList() {
		

			listBingo({ page: this.page, size: this.size, type: this.type }).then(res => {
				this.list = res.data.list
				this.total = res.data.total
			})

		},

		toURL(str) {
			const url = "/cn/list/mine?tab=" + str
			this.$router.push(url)
		}


	},
};
</script>

<style lang="scss" scoped>
.shenhezhong {
	width: 48px;
	height: 20px;
	margin-right: 10px;
	color: #fff;
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	background: #E77817;
	border-radius: 3px;
}

.fengeline {
	width: 936px;
	height: 4px;
	background: #F0F3F8;
}

.listcontent {
	width: 936px;
	min-height: 589px;
	background: #FFFFFF;



	.bingoitem {
		display: flex;
		height: 227px;
		justify-content: flex-start;
		align-items: center;
		margin-left: 20px;
		margin-right: 20px;

		border-bottom: solid 2px #f2f2f2;


		.cover {
			width: 308px;
			height: 187px;
			margin-right: 20px;
			border: solid 1px #dedede;
		}


		.rightlist {
			height: 187px;
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			// background:red;
			align-items: flex-start;

			.title {
				width: 256px;
				height: 22px;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #000000;
				line-height: 22px;
				text-align: left;
			}

			.line {
				display: flex;

				.lineLift {
					margin-right: 20px;
					height: 17px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;
					line-height: 17px;
				}
			}
		}

	}

	.xuqiulist {
		.xuqiuitem {
			display: flex;
			height: 91px;
			width: 896px;
			flex-direction: column;
			align-items: center;
			margin-left: 20px;
			margin-right: 20px;
			border-bottom: solid 2px #f2f2f2;
			justify-content: space-around;

			.itemtop {

				display: flex;
				width: 100%;
				justify-content: space-between;


				.title {
					flex: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					height: 22px;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;
					line-height: 22px;
					text-align: left;
				}

				.date {
					width: 229px;
					height: 17px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #A0A0A0;
					line-height: 17px;
				}
			}

			.itembottom {

				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;

				.blue {
					background: RGBA(229, 245, 252, 1);
					display: inline-block;
					padding: 2px 10px;
					border-radius: 4px;
					margin-right: 10px;
					color: RGBA(0, 163, 228, 1)
				}

				.location {
					color: #999;
					font-size: 14px;
					display: flex;
					align-items: center;

				}

			}
		}
	}

	.nonglist {
		.nongitem {
			display: flex;
			height: 62px;
			width: 896px;
			flex-direction: column;
			align-items: center;
			margin-left: 20px;
			margin-right: 20px;
			border-bottom: solid 2px #f2f2f2;
			justify-content: space-around;

			.itemtop {

				display: flex;
				width: 100%;
				justify-content: space-between;


				.title {
					flex: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					height: 22px;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;
					line-height: 22px;
					text-align: left;
				}

				.date {
					width: 229px;
					height: 17px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #A0A0A0;
					line-height: 17px;
				}
			}


		}
	}
}

.tab {
	display: flex;
	width: 100%;
	background: #fff;
}

.btnitem {
	cursor: pointer;
	width: 138px;
	height: 52px;
	color: #000;
	background: #fff;
	text-align: center;
	line-height: 52px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	font-size: 16px;
}

.currentbtn {

	text-align: center;
	line-height: 52px;
	width: 138px;
	color: #fff;
	height: 52px;
	background: #28A7E1;
}

.pointer {
	cursor: pointer;
}
</style>